.container {
  width: 100vw;
  min-height: 100vh;
  max-height: 100%;
  overflow-y: hidden;
  display: flex;
 
}
/* @media (max-width: 946px) {
  .container {
    display: grid;
    grid-template-columns: 4fr 1fr;
  }
}
@media (max-width: 425px) {
  .container {
    display: grid;
    grid-template-columns: 0 1fr;
  }
} */
